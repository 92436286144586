import React from "react";
import Slider from "react-slick";
import Testimonial from "../testimonial/testimonial";
import { NextArrow, PrevArrow } from "./sliderArrow";

class SimpleSlider extends React.Component {
  render() {
    const testimonialImages = this.props.testimonialImages.edges;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    return (
      <Slider {...settings}>
        <Testimonial
          image={testimonialImages[5]}
          name="home.quoteName1"
          company="home.quoteCompany1"
          text="home.quoteText1"
        />
        <Testimonial
          image={testimonialImages[2]}
          name="home.quoteName2"
          company="home.quoteCompany2"
          text="home.quoteText2"
        />
        <Testimonial
          image={testimonialImages[3]}
          name="home.quoteName3"
          company="home.quoteCompany3"
          text="home.quoteText3"
        />
        <Testimonial
          image={testimonialImages[0]}
          name="home.quoteName4"
          company="home.quoteCompany4"
          text="home.quoteText4"
        />
        <Testimonial
          image={testimonialImages[1]}
          name="home.quoteName5"
          company="home.quoteCompany5"
          text="home.quoteText5"
        />
        <Testimonial
          image={testimonialImages[4]}
          name="home.quoteName6"
          company="home.quoteCompany6"
          text="home.quoteText6"
        />
      </Slider>
    );
  }
}

export default SimpleSlider;
