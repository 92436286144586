import React, { Component } from "react";
import { injectIntl } from "gatsby-plugin-react-intl";
import { ARTICLE_ID, PLAYER_URL } from "../../src/constants";
import Img from "gatsby-image";

class Player extends Component {
  render() {
    const { giveItATry, intl } = this.props;
    const playerParams = new URLSearchParams();
    playerParams.set("id", ARTICLE_ID);
    playerParams.set("initial_view", "player");
    playerParams.set("txt_color", "666666");
    playerParams.set("fg_color", "e74c3c");
    playerParams.set("bg_color", "ffffff");
    playerParams.set("fr_color", "cbcbcb");
    playerParams.set("player_radius", "5");
    playerParams.set("play_bar_radius", "0");
    playerParams.set("play_button_radius_left", "5");
    playerParams.set("play_button_radius_right", "0");
    playerParams.set("cta_button_radius", "5");

    return (
      <div className="contentWrapper">
        <ImageWrapper locale={intl.locale}>
          <Img
            className="speaker"
            fluid={giveItATry.edges[0].node.childImageSharp.fluid}
          />
          <TryItImage locale={intl.locale} giveItATry={giveItATry} />
        </ImageWrapper>
        <iframe
          title="narando Player"
          src={`${PLAYER_URL}?${playerParams.toString()}`}
          width="100%"
          height="60"
          className="narando-player"
        />
      </div>
    );
  }
}

const TryItImage = (props) => {
  if (props.locale === "de-DE") {
    return (
      <Img
        className="tryItGerman"
        fluid={props.giveItATry.edges[2].node.childImageSharp.fluid}
      />
    );
  }

  return (
    <Img
      className="tryIt"
      fluid={props.giveItATry.edges[1].node.childImageSharp.fluid}
    />
  );
};

const ImageWrapper = (props) => {
  if (props.locale === "de-DE") {
    return <div className="germanImageWrapper">{props.children}</div>;
  }

  return <div className="imageWrapper">{props.children}</div>;
};

export default injectIntl(Player);
