import React from "react";
import "../pages/styling/index.css";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Player from "../components/player";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-react-intl";

export function TopView(props) {
  const { image, giveItATry } = props;
  const intl = useIntl();
  return (
    <div className="container">
      <Img
        title="Product image"
        className="backgroundImage"
        fluid={image.childImageSharp.fluid}
      />
      <div className="centerContainer">
        <h1 className="h1">
          <FormattedMessage id="home.header" />
        </h1>
        <p className="text">
          <FormattedMessage id="home.headerText" />
          <FormattedMessage id="home.headerText2" />
          <FormattedMessage id="home.headerText3" />
        </p>
        <Player locale={intl.locale} giveItATry={giveItATry} />
      </div>
    </div>
  );
}
