import React, { Component } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import "./insights.css";

class Insights extends Component {
  render() {
    const { header, text, Icon } = this.props;

    return (
      <div className="insight">
        <div className="iconHeader">
          <div className="iconWrapper">
            <Icon />
          </div>
          <h3>
            <FormattedMessage id={header} />
          </h3>
        </div>
        <p>
          <FormattedMessage id={text} />
        </p>
      </div>
    );
  }
}

export default Insights;
