import React, { Component } from "react";
import "./partners.css";
import Img from "gatsby-image";

// Index of the very left image
let counter = 0;

class Partners extends Component {
  constructor() {
    super();
    this.state = {
      classes: "partner",
      isMobile: false,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleWindowResize() {
    const oldState = this.state.isMobile;
    this.setState({ isMobile: window.innerWidth < 576 });

    if (oldState != window.innerWidth < 576) {
      counter = 0;
    }
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize, false);
    setInterval(() => {
      this.setState({ classes: "partner fadeOut" });
      setTimeout(() => {
        this.newImages();
        this.setState({ classes: "partner fadeIn" });
      }, 1000);
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize, false);
  }

  newImages() {
    const numberOfImages = this.props.images.edges.length;
    const numberOfPartners = this.state.isMobile ? 2 : 3;
    // When this is true all partner images got displayed and it will start at the first image again
    if (counter === numberOfImages - numberOfPartners) {
      counter = 0;
      return;
    }
    counter += numberOfPartners;
  }

  render() {
    const { classes, isMobile } = this.state;
    const { images } = this.props;
    const firstImage = images.edges[counter].node.childImageSharp.fluid;
    const secondImage = images.edges[counter + 1].node.childImageSharp.fluid;
    const thirdImage = !isMobile
      ? images.edges[counter + 2].node.childImageSharp.fluid
      : null;

    return (
      <div className="partners">
        <div className="partnersWrapper">
          <div className={classes}>
            <Img fluid={firstImage} />
          </div>
          <div className={classes}>
            <Img fluid={secondImage} />
          </div>
          {thirdImage && (
            <div className={classes}>
              <Img fluid={thirdImage} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Partners;
