import React, { Component } from "react";
import { graphql } from "gatsby";

import { Cogs } from "../assets/icons/Cogs";
import { Microphone } from "../assets/icons/Microphone";
import { Dollar } from "../assets/icons/Dollar";
import "./styling/index.css";
import "../components/slider/slick.css";
import "../components/testimonial/testimonial.css";
import Layout from "../components/layout/layout";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-react-intl";
import Partners from "../components/partners/partners";
import { TopView } from "../components/topView";
import Info from "../components/info/info";
import Insights from "../components/insights/insights";
import SimpleSlider from "../components/slider/SimpleSlider";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayButton: false,
      color: "transparent",
      isMobile: false,
    };
    this.calcScroll = this.calcScroll.bind(this, 350);
    this.mobileChanged = this.mobileChanged.bind(this);
  }

  //Scroll to top Functions
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), 17);
    this.setState({ intervalId: intervalId });
  }

  //mount and unmount functions
  componentDidMount() {
    this.mobileChanged();
    window.addEventListener("resize", this.mobileChanged, false);
    window.addEventListener("scroll", this.calcScroll);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.mobileChanged, false);
    window.removeEventListener("scroll", this.calcScroll);
  }

  mobileChanged() {
    this.setState({
      isMobile: window.innerWidth < 768,
    });
  }

  // Scroll-trigger function
  calcScroll(h1) {
    var _window = window;
    var heightDiff = parseInt(h1);
    var scrollPos = _window.scrollY;
    if (scrollPos > heightDiff) {
      this.setState({
        displayButton: true,
        color: "white",
      });
    } else {
      this.setState({
        displayButton: false,
        color: "transparent",
      });
    }
  }

  render() {
    const { intl } = this.props;
    const { pathname: path } = this.props.location;
    const {
      firstProductImage,
      secondProductImage,
      headerImage,
      mobileHeaderImage,
      partnerImages,
      giveItATry,
      testimonialImages,
    } = this.props.data;
    return (
      <Layout
        color={this.state.color}
        path={path}
        title={intl.formatMessage({ id: "home.title" })}
      >
        <TopView
          Tag="section"
          giveItATry={giveItATry}
          image={this.state.isMobile ? mobileHeaderImage : headerImage}
          backgroundColor={`#ffffff`}
          className="indexImage"
        />
        <Partners images={partnerImages} />
        <div className="insightsContainer">
          <Insights
            header="home.firstInsightHeader"
            text="home.firstInsightText"
            Icon={Microphone}
          />
          <Insights
            header="home.secondInsightHeader"
            text="home.secondInsightText"
            Icon={Cogs}
          />
          <Insights
            header="home.thirdInsightHeader"
            text="home.thirdInsightText"
            Icon={Dollar}
          />
        </div>
        <div className="infoWrapper">
          <Info
            body="home.firstInfoText"
            header="home.firstInfoHeader"
            link="/player-widget"
            linkName="home.firstInfoLinkName"
            image={firstProductImage}
          />
          <Info
            body="home.secondInfoText"
            header="home.secondInfoHeader"
            link="https://narando.com/narrators"
            linkName="home.secondInfoLinkName"
            image={secondProductImage}
          />
        </div>
        <div className="quote">
          <div className="maxWrapper">
            <SimpleSlider testimonialImages={testimonialImages} />
            <Link to="/contact">
              <button className="quoteContact">
                <FormattedMessage id="home.contactButton" />
              </button>
            </Link>
          </div>
        </div>
        {this.state.displayButton && (
          <button
            title="Back to top"
            className="scroll"
            onClick={() => {
              this.scrollToTop();
            }}
          />
        )}
        <div className="fillContainer" />
      </Layout>
    );
  }
}

export default injectIntl(Index);

export const pageQuery = graphql`
  query {
    partnerImages: allFile(
      filter: {
        sourceInstanceName: { eq: "assets" }
        relativeDirectory: { eq: "press" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    testimonialImages: allFile(
      filter: {
        sourceInstanceName: { eq: "assets" }
        relativeDirectory: { eq: "testimonials" }
      }
      sort: { fields: name, order: DESC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    giveItATry: allFile(
      filter: {
        sourceInstanceName: { eq: "assets" }
        relativeDirectory: { eq: "giveItATry" }
        extension: { eq: "png" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "index/background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeaderImage: file(
      relativePath: { eq: "index/mobileBackground.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1536) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firstProductImage: file(relativePath: { eq: "index/productImage1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondProductImage: file(relativePath: { eq: "index/productImage2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
