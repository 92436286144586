import React, { Component } from "react";
import "./info.css";
import Img from "gatsby-image";

import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";

class Info extends Component {
  render() {
    const { image, body, header, link, linkName, intl } = this.props;

    return (
      <div className="infoContainer">
        <div className="infoImageWrapper infoImage">
          <Img
            alt={intl.formatMessage({ id: "info.productImage" })}
            fluid={image.childImageSharp.fluid}
          />
        </div>
        <div className="textContainer">
          <h2>
            <FormattedMessage id={header} />
          </h2>
          <p className="infoText">
            <FormattedMessage id={body} />
            &nbsp;
            <a href={link}>
              <FormattedMessage id={linkName} />
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default injectIntl(Info);
