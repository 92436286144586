import React from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";

class Testimonial extends React.Component {
  render() {
    const { image, company, name, text } = this.props;
    return (
      <div className="sliderBox">
        <p className="quoteText">
          <FormattedMessage id={text} />
        </p>
        <div className="sponsorWrapper">
          <Img
            fluid={image.node.childImageSharp.fluid}
            className="sponsorImage"
          />
          <div className="quoteSponsor">
            <p className="name">
              <FormattedMessage id={name} />
            </p>
            <p className="company">
              <FormattedMessage id={company} />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
